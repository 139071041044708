import getCurrentLayout from "tenants/get-current-layout"
import getUrlToLayout from "shared/get-url-to-layout"

export default async function getPathToLayout(layout, pathName, ...args) {
  const currentLayout = getCurrentLayout()
  const fullPathName = `${pathName}Path`

  let routes

  if (layout === "admin") {
    routes = AdminRoutes
  } else if (layout === "nemoa") {
    routes = NemoaRoutes
  } else if (layout === "merfoto") {
    routes = MerfotoRoutes
  } else {
    throw new Error(`Unknown layout: ${layout}`)
  }

  if (!routes[fullPathName]) {
    throw new Error(`No such route on the ${layout} layout: ${fullPathName}`)
  }

  if (currentLayout === layout) {
    return routes[fullPathName](...args)
  }

  const url = await getUrlToLayout(layout, pathName, ...args)

  return url
}
