import {BaseModel} from "@kaspernj/api-maker"
import I18nOnSteroids from "i18n-on-steroids"

const i18n = new I18nOnSteroids()
const baseFiles = require.context("../../../config/locales", true, /^.\/([a-z]+)\.yml$/)
const customModelFiles = require.context("../../../config/locales/models", true, /^(.+)\.yml$/)
const dateTimeFiles = require.context("../../../config/locales/awesome_translations/date_time", true, /^(.+)\.yml$/)
const jsFiles = require.context("../../../config/locales/awesome_translations/js", true, /^(.+)\.yml$/)
const modelFiles = require.context("../../../config/locales/awesome_translations/models", true, /^(.+)\.yml$/)
const numbersFiles = require.context("../../../config/locales/awesome_translations/numbers", true, /^(.+)\.yml$/)

i18n.scanRequireContext(baseFiles)
i18n.scanRequireContext(customModelFiles)
i18n.scanRequireContext(dateTimeFiles)
i18n.scanRequireContext(jsFiles)
i18n.scanRequireContext(modelFiles)
i18n.scanRequireContext(numbersFiles)

i18n.setLocale("da")
i18n.setLocaleOnStrftime()

BaseModel.setI18n(i18n)

export default i18n
