import formSerialize from "form-serialize"
import merge from "merge"
import qs from "qs"

// TODO this should use hooks in components
export default class Params {
  static parse() {
    const searchString = window.location.search.substr(1)
    const parsed = qs.parse(searchString, {depth: 99})

    return parsed
  }

  static change(given) {
    return merge.recursive(true, Params.parse(), given)
  }

  static updatedPathWithchangedParams(given) {
    const params = Params.change(given)
    const newParams = Params.stringify(params)

    return `${location.pathname}?${newParams}`
  }

  static currentPath() {
    return `${location.pathname}${location.search}${location.hash}`
  }

  static serializeForm(form) {
    const hash = formSerialize(form, {empty: true, hash: true})

    return Params.setUndefined(hash)
  }

  // This is used to set all empty values to 'undefined' which makes qs removed those elements from the query string
  static setUndefined(given) {
    if (Array.isArray(given)) {
      if (given.length == 0) {
        return undefined
      }

      return given.map((givenI) => Params.setUndefined(givenI))
    } else if (typeof given === "object") {
      if (Object.keys(given).length == 0) {
        return undefined
      }

      const newGiven = {}

      for (const key in given) {
        newGiven[key] = Params.setUndefined(given[key])
      }

      return newGiven
    } else if (given === "") {
      return undefined
    }

    return given
  }

  static stringify(value) {
    return qs.stringify(value)
  }
}
