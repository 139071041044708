import "controllers"
import {BrowserRouter, useRoutes} from "react-router-dom"
import React, {Suspense} from "react"
import {App} from "components/app"
import {CanCanApp} from "shared/can-can-app"
import FlashMessages from "nemoa/components/flash-messages"
import ReactDOM from "react-dom"
import {readRoutes} from "shared/resource-routes"
import ScrollToTop from "shared/scroll-to-top"

require("stylesheets/application")
require("stylesheets/contained-image")
require("shared/devise")
require("shared/error-logger")
require("shared/i18n")
require("shared/session-status-updater")

const requireComponent = (component) => React.lazy(() => import(/* webpackChunkName: "[request]" */ `nemoa/routes/${component}`))

function RouteDefinitions() {
  const routes = readRoutes({
    requireComponent,
    routeDefinitions: require("nemoa/route-definitions.json")
  })

  const element = useRoutes(routes)

  return element
}

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")

  ReactDOM.render((
    <BrowserRouter>
      <App>
        <ScrollToTop />
        <FlashMessages />
        <Suspense fallback={<div />}>
          <CanCanApp abilities={[["admin", ["access"]]]}>
            <RouteDefinitions />
          </CanCanApp>
        </Suspense>
      </App>
    </BrowserRouter>
  ), reactRoot)
})
