import {CustomError, ValidationError} from "@kaspernj/api-maker"

export default class FlashMessage {
  static component(args) {
    const event = new CustomEvent("pushFlashMessage", {
      detail: {
        args,
        title: args.title,
        type: "component"
      }
    })

    window.dispatchEvent(event)
  }

  static error(message) {
    const event = new CustomEvent("pushFlashMessage", {
      detail: {
        message,
        title: I18n.t("js.global.error"),
        type: "error"
      }
    })

    window.dispatchEvent(event)
  }

  static errorResponse(error) {
    if (error instanceof ValidationError && !error.hasUnhandledErrors()) {
      FlashMessage.error(I18n.t("js.flash_message.couldnt_submit_because_of_validation_errors"))
    } else if (error instanceof CustomError) {
      const bugReportId = error.args?.response?.bug_report_id
      const bugReportInstanceId = error.args?.response?.bug_report_instance_id
      const errors = error.args?.response?.errors

      if (errors) {
        const errorMessages = errors.map((error) => {
          if (typeof error == "string") {
            return error
          }

          return digg(error, "message")
        })

        if (bugReportId && bugReportInstanceId) {
          errorMessages.push(
            I18n.t("js.flash_message.bug_id_and_instance_id_was", {bug_report_id: bugReportId, bug_report_instance_id: bugReportInstanceId})
          )
        }

        FlashMessage.error(errorMessages.join(". "))
      } else {
        throw error
      }
    } else {
      console.error("Didnt know what to do with that error", error)
      throw error
    }
  }

  static success(message) {
    const event = new CustomEvent("pushFlashMessage", {
      detail: {
        message,
        title: I18n.t("js.global.success"),
        type: "success"
      }
    })

    window.dispatchEvent(event)
  }
}
