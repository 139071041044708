import "./style"
import {useEffect, useState} from "react"

FlashMessagesFlashMessage.propTypes = PropTypesExact({
  className: PropTypes.string,
  component: PropTypes.node,
  count: PropTypes.number.isRequired,
  message: PropTypes.string,
  onRequestRemove: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  timeout: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
})

FlashMessagesFlashMessage.defaultProps = {
  timeout: 4000
}

export default function FlashMessagesFlashMessage(props) {
  const {className, component, message, onRequestRemove, title, timeout, type} = props
  const [messageTimeout, setMessageTimeout] = useState()

  const onMouseEnter = () => {
    clearTimeout(messageTimeout)
  }

  const startTimeout = () => {
    setMessageTimeout(setTimeout(onRequestRemove, timeout))
  }

  // TODO write timeout hook
  useEffect(() => {
    if (timeout) {
      startTimeout()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onMouseLeave = () => {
    if (timeout) {
      startTimeout()
    }
  }

  const onRemovedClicked = (e) => {
    e.preventDefault()

    onRequestRemove()
  }

  return (
    <div
      className={classNames("nemoa-components-flash-messages-flash-message", className)}
      data-type={type}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="d-flex">
        <div className="flex-grow-1">
          {title &&
            <div className="mb-1 flash-message-title">
              <b>{title}</b>
            </div>
          }
        </div>
        <Icon className="close-flash-message-button" icon="times" onClick={onRemovedClicked} size="small" />
      </div>
      <div className="flash-message">
        {component}
        {message}
      </div>
    </div>
  )
}
