import blockUIEvents from "components/block-ui/events"

export default class BaseComponent extends React.Component {
  blockUI = async (callback) => {
    try {
      blockUIEvents.emit("blockUI")

      return await callback()
    } finally {
      blockUIEvents.emit("unblockUI")
    }
  }
}
