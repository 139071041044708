import getPathToLayout from "../get-path-to-layout"
import {useCallback} from "react"
import {useNavigate} from "react-router-dom"

export function useLayoutRedirector() {
  const navigate = useNavigate()
  const callback = useCallback(async(layout, pathName, ...args) => {
    const pathOrUrl = await getPathToLayout(layout, pathName, ...args)

    if (pathOrUrl.match(/^http(s|):\/\//)) {
      location.href = pathOrUrl
    } else {
      navigate(pathOrUrl)
    }
  }, [navigate])

  return callback
}
