import "./style"
import Tooltip from "components/tooltip"

function Icon(props) {
  const {className, disabled, icon, iconStyle, muted, onClick, size, tooltip, ...restProps} = props
  const onClickProxy = (event) => {
    if (onClick === undefined || disabled) {
      return
    }

    return onClick(event)
  }

  const iconContent = (
    <i
      className={classNames("components-icon", `la${iconStyle[0]}`, `la-${icon}`, className, {"text-muted": muted})}
      data-disabled={disabled}
      data-icon={icon}
      data-onclick={Boolean(onClick)}
      data-size={size}
      onClick={onClickProxy}
      {...restProps}
    />
  )

  if (tooltip) {
    return (
      <Tooltip tip={tooltip}>
        {iconContent}
      </Tooltip>
    )
  }

  return iconContent
}

Icon.defaultProps = {
  disabled: false,
  iconStyle: "solid",
  muted: false
}

Icon.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.oneOf([
    "angle-down",
    "angle-left",
    "angle-right",
    "angle-up",
    "ambulance",
    "assistive-listening-systems",
    "balance-scale",
    "ban",
    "bars",
    "bell",
    "book",
    "bullhorn",
    "calendar",
    "calendar-alt",
    "calendar-plus",
    "camera",
    "camera-retro",
    "caret-down",
    "caret-right",
    "cart-arrow-down",
    "cart-plus",
    "certificate",
    "chart-pie",
    "check",
    "check-circle",
    "check-double",
    "clipboard",
    "clock",
    "cog",
    "columns",
    "comment-dots",
    "comments",
    "compress-arrows-alt",
    "copy",
    "credit-card",
    "crown",
    "door-open",
    "download",
    "edit",
    "ellipsis-h",
    "ellipsis-v",
    "envelope",
    "eye",
    "exclamation-circle",
    "exclamation-triangle",
    "expand",
    "expand-arrows-alt",
    "external-link-alt",
    "eye-slash",
    "file",
    "file-alt",
    "file-archive",
    "file-csv",
    "file-export",
    "file-image",
    "file-invoice",
    "file-invoice-dollar",
    "flag",
    "folder-open",
    "folder-plus",
    "history",
    "horse",
    "home",
    "images",
    "inbox",
    "key",
    "laptop",
    "list",
    "location-arrow",
    "lock",
    "lock-open",
    "minus",
    "money-bill-alt",
    "money-bill-wave",
    "mountain",
    "palette",
    "paper-plane",
    "pen",
    "pencil-alt",
    "phone",
    "phone-alt",
    "phone-slash",
    "photo-video",
    "piggy-bank",
    "play",
    "plane-arrival",
    "plane-departure",
    "plus",
    "plus-circle",
    "portrait",
    "print",
    "random",
    "receipt",
    "recycle",
    "redo-alt",
    "restroom",
    "save",
    "search",
    "share",
    "share-alt-square",
    "shield-alt",
    "shipping-fast",
    "shopping-basket",
    "shopping-cart",
    "sign-in-alt",
    "sign-out-alt",
    "sitemap",
    "snowboarding",
    "stamp",
    "star",
    "step-backward",
    "step-forward",
    "store",
    "sync",
    "table",
    "tags",
    "times",
    "times-circle",
    "thumbs-down",
    "thumbs-up",
    "tools",
    "trash",
    "truck-moving",
    "vote-yea",
    "undo-alt",
    "unlock",
    "upload",
    "user",
    "user-cog",
    "user-friends",
    "user-plus",
    "users"
  ]).isRequired,
  iconStyle: PropTypes.oneOf(["brand", "regular", "solid"]).isRequired,
  muted: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf([
    "extra-large",
    "extra-small",
    "large",
    "medium",
    "small"
  ]),
  tooltip: PropTypes.node
}
export default Icon
