import {useContext, useEffect} from "react"
import {useRecoilState, useRecoilValue} from "recoil"
import {canCanAtom} from "./atoms/can-can-atom"
import {tenantAtom} from "./atoms/tenant-atom"
import {UserContext} from "./user-context"

export function CanCanApp({abilities, children}) {
  const currentTenant = useRecoilValue(tenantAtom)
  const [currentUser] = useContext(UserContext)
  const [canCan, setCanCan] = useRecoilState(canCanAtom)

  useEffect(() => {
    (async() => {
      const canCan = CanCan.current()

      await canCan.loadAbilities(abilities)

      setCanCan(canCan)
    })()
  }, [abilities, canCan, currentUser, currentTenant, setCanCan])

  return (
    <>
      <EventEmitterListener
        event="onResetAbilities"
        events={CanCan.current().events}
        onCalled={() => setCanCan(undefined)}
      />
      {children}
    </>
  )
}
