/* rails-erb-loader-dependencies ../config/routes.rb ../config/initializers/js_routes.rb ./javascript/admin/route-definitions.json ./javascript/merfoto/route-definitions.json ./javascript/nemoa/route-definitions.json */

/**
 * File generated by js-routes 2.2.2
 * Based on Rails 6.1.5 routes of Nemoa::Application
 */
((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, "Routes", routes);
            },
            isSupported() {
                return !"Routes" || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{
  activitiesUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]], true),

  activitiesPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]),

  activitiesMineUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[6,"mine"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  activitiesMinePath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[6,"mine"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  activityUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  activityPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  activityElectionsUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"elections"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  activityElectionsPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"elections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  activityLiveUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"live"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  activityLivePath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"live"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  activityPoliticsUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"politics"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  activityPoliticsPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"politics"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  activitySurveyUrl: __jsr.r({"activity_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  activitySurveyPath: __jsr.r({"activity_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  activitySurveyTeamSurveyCompleteUrl: __jsr.r({"activity_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true),

  activitySurveyTeamSurveyCompletePath: __jsr.r({"activity_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  albumUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"albums"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  albumPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"albums"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  albumsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"albums"],[1,[2,[8,"."],[3,"format"]]]]], true),

  albumsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"albums"],[1,[2,[8,"."],[3,"format"]]]]]),

  apiMakerUrl: __jsr.r({}, [2,[7,"/"],[6,"api_maker"]], true),

  apiMakerPath: __jsr.r({}, [2,[7,"/"],[6,"api_maker"]]),

  apiMakerCommandsUrl: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"api_maker"]],[7,"/"]],[2,[6,"commands"],[1,[2,[8,"."],[3,"format"]]]]], true),

  apiMakerCommandsPath: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"api_maker"]],[7,"/"]],[2,[6,"commands"],[1,[2,[8,"."],[3,"format"]]]]]),

  apiMakerSessionStatusesUrl: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"api_maker"]],[7,"/"]],[2,[6,"session_statuses"],[1,[2,[8,"."],[3,"format"]]]]], true),

  apiMakerSessionStatusesPath: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"api_maker"]],[7,"/"]],[2,[6,"session_statuses"],[1,[2,[8,"."],[3,"format"]]]]]),

  basketUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basket"],[1,[2,[8,"."],[3,"format"]]]]], true),

  basketPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"basket"],[1,[2,[8,"."],[3,"format"]]]]]),

  blankUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]], true),

  blankPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]]),

  blankDuplicate1Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]], true),

  blankDuplicate1Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]]),

  blankDuplicate2Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]], true),

  blankDuplicate2Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]]),

  checkoutCompletedUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"completed"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  checkoutCompletedPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"completed"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  checkoutConfirmUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  checkoutConfirmPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"confirm"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  checkoutCustomerInformationUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"customer_information"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  checkoutCustomerInformationPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"customer_information"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  checkoutDeliveryUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"delivery"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  checkoutDeliveryPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"delivery"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  checkoutPaymentUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  checkoutPaymentPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"checkout"],[2,[7,"/"],[2,[6,"payment"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  chooseSurveyCompetitionUrl: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"choose_survey"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  chooseSurveyCompetitionPath: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"choose_survey"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  chooseTeamCompetitionUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  chooseTeamCompetitionPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_team"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  chooseTeamTypeCompetitionUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_team_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  chooseTeamTypeCompetitionPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"choose_team_type"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  ckeditorUrl: __jsr.r({}, [2,[7,"/"],[6,"ckeditor"]], true),

  ckeditorPath: __jsr.r({}, [2,[7,"/"],[6,"ckeditor"]]),

  ckeditorPicturesUrl: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[1,[2,[8,"."],[3,"format"]]]]], true),

  ckeditorPicturesPath: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[1,[2,[8,"."],[3,"format"]]]]]),

  ckeditorPictureUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  ckeditorPicturePath: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"pictures"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  ckeditorAttachmentFilesUrl: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[1,[2,[8,"."],[3,"format"]]]]], true),

  ckeditorAttachmentFilesPath: __jsr.r({"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[1,[2,[8,"."],[3,"format"]]]]]),

  ckeditorAttachmentFileUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  ckeditorAttachmentFilePath: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"ckeditor"]],[7,"/"]],[2,[6,"attachment_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  competitionUrl: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  competitionPath: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  competitionSurveyUrl: __jsr.r({"competition_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  competitionSurveyPath: __jsr.r({"competition_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  competitionSurveyFeedbackAssignmentUrl: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  competitionSurveyFeedbackAssignmentPath: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  competitionSurveyTeamSurveyCompleteUrl: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true),

  competitionSurveyTeamSurveyCompletePath: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  competitionTeamUrl: __jsr.r({"competition_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  competitionTeamPath: __jsr.r({"competition_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  competitionsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[1,[2,[8,"."],[3,"format"]]]]], true),

  competitionsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[1,[2,[8,"."],[3,"format"]]]]]),

  cookiesAndPrivacyPolicyUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cookies_and_privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]], true),

  cookiesAndPrivacyPolicyPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cookies_and_privacy_policy"],[1,[2,[8,"."],[3,"format"]]]]]),

  downloadsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[1,[2,[8,"."],[3,"format"]]]]], true),

  downloadsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"downloads"],[1,[2,[8,"."],[3,"format"]]]]]),

  editActivitySurveyTeamSurveyUserStepUrl: __jsr.r({"activity_id":{"r":true},"survey_id":{"r":true},"team_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"user_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]], true),

  editActivitySurveyTeamSurveyUserStepPath: __jsr.r({"activity_id":{"r":true},"survey_id":{"r":true},"team_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"user_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]),

  editActivityTeamSurveyUrl: __jsr.r({"activity_id":{"r":true},"team_survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  editActivityTeamSurveyPath: __jsr.r({"activity_id":{"r":true},"team_survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"activity_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  editCompetitionSurveyFeedbackSurveyFeedbackStepUrl: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"feedback_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_surveys"],[2,[7,"/"],[2,[3,"feedback_survey_id"],[2,[7,"/"],[2,[6,"feedback_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]], true),

  editCompetitionSurveyFeedbackSurveyFeedbackStepPath: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"feedback_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_surveys"],[2,[7,"/"],[2,[3,"feedback_survey_id"],[2,[7,"/"],[2,[6,"feedback_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]),

  editCompetitionSurveyTeamSurveyUserStepUrl: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"team_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"user_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]], true),

  editCompetitionSurveyTeamSurveyUserStepPath: __jsr.r({"competition_id":{"r":true},"survey_id":{"r":true},"team_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"user_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]),

  editCompetitionTeamSurveyUrl: __jsr.r({"competition_id":{"r":true},"team_survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  editCompetitionTeamSurveyPath: __jsr.r({"competition_id":{"r":true},"team_survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  editOrderLineUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_lines"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  editOrderLinePath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"order_lines"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  editSurveyFeedbackSurveyFeedbackStepUrl: __jsr.r({"survey_id":{"r":true},"feedback_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_surveys"],[2,[7,"/"],[2,[3,"feedback_survey_id"],[2,[7,"/"],[2,[6,"feedback_steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  editSurveyFeedbackSurveyFeedbackStepPath: __jsr.r({"survey_id":{"r":true},"feedback_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_surveys"],[2,[7,"/"],[2,[3,"feedback_survey_id"],[2,[7,"/"],[2,[6,"feedback_steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  editSurveyTeamSurveyUserStepUrl: __jsr.r({"survey_id":{"r":true},"team_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"user_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true),

  editSurveyTeamSurveyUserStepPath: __jsr.r({"survey_id":{"r":true},"team_survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"user_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  editTeamSurveyUrl: __jsr.r({"team_survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  editTeamSurveyPath: __jsr.r({"team_survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"team_survey_id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  editUserUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  editUserPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  editUserPasswordUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  editUserPasswordPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  editUserPasswordDuplicate1Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  editUserPasswordDuplicate1Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  editUserPasswordDuplicate2Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  editUserPasswordDuplicate2Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  electionUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"elections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  electionPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"elections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  errorTestsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"error_tests"],[1,[2,[8,"."],[3,"format"]]]]], true),

  errorTestsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"error_tests"],[1,[2,[8,"."],[3,"format"]]]]]),

  errorsStacktraceTestUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"errors"],[2,[7,"/"],[2,[6,"stacktrace_test"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  errorsStacktraceTestPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"errors"],[2,[7,"/"],[2,[6,"stacktrace_test"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  fakeUniLoginUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"fake"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  fakeUniLoginPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"fake"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  fakeUniLoginDuplicate1Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"fake"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  fakeUniLoginDuplicate1Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"fake"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  joblerUrl: __jsr.r({}, [2,[7,"/"],[6,"jobler"]], true),

  joblerPath: __jsr.r({}, [2,[7,"/"],[6,"jobler"]]),

  joblerDownloadUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"jobler"]],[7,"/"]],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  joblerDownloadPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"jobler"]],[7,"/"]],[2,[6,"downloads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  joblerJobUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"jobler"]],[7,"/"]],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  joblerJobPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[2,[2,[7,"/"],[6,"jobler"]],[7,"/"]],[2,[6,"jobs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  navigationUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"navigations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  navigationPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"navigations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  navigationsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"navigations"],[1,[2,[8,"."],[3,"format"]]]]], true),

  navigationsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"navigations"],[1,[2,[8,"."],[3,"format"]]]]]),

  newCompetitionSessionUrl: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  newCompetitionSessionPath: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  newCompetitionTeamUrl: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  newCompetitionTeamPath: __jsr.r({"competition_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"competition_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  newOrderLineUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"order_lines"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  newOrderLinePath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"order_lines"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  newSchoolElectionUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  newSchoolElectionPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  newUniLoginUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  newUniLoginPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  newUserConfirmationUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  newUserConfirmationPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  newUserConfirmationDuplicate1Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  newUserConfirmationDuplicate1Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  newUserConfirmationDuplicate2Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  newUserConfirmationDuplicate2Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  newUserPasswordUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  newUserPasswordPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  newUserPasswordDuplicate1Url: __jsr.r({}, [7,"/"], true),

  newUserPasswordDuplicate1Path: __jsr.r({}, [7,"/"]),

  newUserPasswordDuplicate2Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  newUserPasswordDuplicate2Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  newUserPasswordDuplicate3Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  newUserPasswordDuplicate3Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"passwords"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  newUserSessionUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  newUserSessionPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  newUserSessionDuplicate1Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  newUserSessionDuplicate1Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"sign_in"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  oneTimePassswordsQrImageUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"one_time_passswords"],[2,[7,"/"],[2,[6,"qr_image"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  oneTimePassswordsQrImagePath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"one_time_passswords"],[2,[7,"/"],[2,[6,"qr_image"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  ordersUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]], true),

  ordersPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]),

  paymentsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]], true),

  paymentsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"payments"],[1,[2,[8,"."],[3,"format"]]]]]),

  railsBlobRepresentationUrl: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true),

  railsBlobRepresentationPath: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  railsBlobRepresentationProxyUrl: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true),

  railsBlobRepresentationProxyPath: __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  railsDirectUploadsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  railsDirectUploadsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  railsDiskServiceUrl: __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  railsDiskServicePath: __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  railsServiceBlobUrl: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  railsServiceBlobPath: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  railsServiceBlobProxyUrl: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  railsServiceBlobProxyPath: __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  reactRootUrl: __jsr.r({}, [7,"/"], true),

  reactRootPath: __jsr.r({}, [7,"/"]),

  reactRootDuplicate1Url: __jsr.r({}, [7,"/"], true),

  reactRootDuplicate1Path: __jsr.r({}, [7,"/"]),

  reactRootDuplicate2Url: __jsr.r({}, [7,"/"], true),

  reactRootDuplicate2Path: __jsr.r({}, [7,"/"]),

  reactRootDuplicate3Url: __jsr.r({}, [7,"/"], true),

  reactRootDuplicate3Path: __jsr.r({}, [7,"/"]),

  reimbursementsAttachmentsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  reimbursementsAttachmentsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  reimbursementsExpensesUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"expenses"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  reimbursementsExpensesPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"expenses"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  reimbursementsTimeUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"time"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  reimbursementsTimePath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"time"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  reimbursementsTravelUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"travel"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  reimbursementsTravelPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"travel"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  rootUrl: __jsr.r({}, [7,"/"], true),

  rootPath: __jsr.r({}, [7,"/"]),

  schoolElectionUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  schoolElectionPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  schoolElectionSignUpUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  schoolElectionSignUpPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_up"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  schoolElectionVoteUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"vote"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  schoolElectionVotePath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"school_elections"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"vote"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  settingsUserUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  settingsUserPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"settings"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  sidekiqWebUrl: __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]], true),

  sidekiqWebPath: __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]),

  statusUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]], true),

  statusPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"status"],[1,[2,[8,"."],[3,"format"]]]]]),

  successfulUniLoginUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"successful"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  successfulUniLoginPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"successful"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  surveyUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  surveyPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  surveyFeedbackAssignmentUrl: __jsr.r({"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  surveyFeedbackAssignmentPath: __jsr.r({"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"feedback_assignments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  surveyTeamSurveyCompleteUrl: __jsr.r({"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  surveyTeamSurveyCompletePath: __jsr.r({"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  surveysUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]], true),

  surveysPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]]),

  teamSurveyUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  teamSurveyPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  teamSurveysUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]], true),

  teamSurveysPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]),

  tenantAbilitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantAbilitiesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantAbilityGroupUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantAbilityGroupPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantAbilityGroupAbilitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantAbilityGroupAbilitiesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantAbilityGroupsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantAbilityGroupsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantActivitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantActivitiesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantActivityUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantActivityPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantActivityActivityGroupsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[6,"activity_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantActivityActivityGroupsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[6,"activity_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantActivityActivityParticipantTypeRulesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity_participant_type_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivityActivityParticipantTypeRulesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity_participant_type_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantActivityActivityParticipantsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivityActivityParticipantsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantActivityActivityPermittedContactTypesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity_permitted_contact_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivityActivityPermittedContactTypesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activity_permitted_contact_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantActivityBillingUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivityBillingPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"billing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantActivityChildrenUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"children"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivityChildrenPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"children"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantActivityElectionsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"elections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivityElectionsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"elections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantActivityLiveAgendaUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"live"],[2,[7,"/"],[2,[6,"agenda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantActivityLiveAgendaPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"live"],[2,[7,"/"],[2,[6,"agenda"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantActivityLiveSetupUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"live"],[2,[7,"/"],[2,[6,"setup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantActivityLiveSetupPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"live"],[2,[7,"/"],[2,[6,"setup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantActivityParticipantsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantActivityParticipantsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantActivityParticipantsRequestedUnregisterUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_participants"],[2,[7,"/"],[2,[6,"requested_unregister"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantActivityParticipantsRequestedUnregisterPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_participants"],[2,[7,"/"],[2,[6,"requested_unregister"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantActivityPoliticsProposalTypesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"politics"],[2,[7,"/"],[2,[6,"proposal_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantActivityPoliticsProposalTypesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"politics"],[2,[7,"/"],[2,[6,"proposal_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantActivityPoliticsProposalsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"politics"],[2,[7,"/"],[2,[6,"proposals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantActivityPoliticsProposalsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"politics"],[2,[7,"/"],[2,[6,"proposals"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantActivitySurveysUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantActivitySurveysPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantBlankUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantBlankPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"blank"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantCallListUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantCallListPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantCallListsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantCallListsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantCallListsIncomingUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[6,"incoming"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantCallListsIncomingPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[6,"incoming"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantCommunicationUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"communication"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantCommunicationPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"communication"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantCommunicationSendingRulesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"sending_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantCommunicationSendingRulesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"sending_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantCommunicationTemplatesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantCommunicationTemplatesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"communication"],[2,[7,"/"],[2,[6,"templates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantCompetitionUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantCompetitionPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantCompetitionsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competitions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantCompetitionsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competitions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantContactUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantContactPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantContactActivitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactActivitiesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactCallListsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"call_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactCallListsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"call_lists"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactContactAssociatesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contact_associates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactContactAssociatesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contact_associates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactContactGroupsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contact_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactContactGroupsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"contact_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactEconomyAndIntegrationsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"economy_and_integrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactEconomyAndIntegrationsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"economy_and_integrations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactEmailsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactEmailsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactGroupsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contact_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantContactGroupsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contact_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantContactInvoicesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactInvoicesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactLogsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactLogsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactMembershipsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactMembershipsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"memberships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactNotesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactNotesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactOrdersUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactOrdersPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactPersonRolesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactPersonRolesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"person_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactPersonUserTeamSurveysUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactPersonUserTeamSurveysPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactPersonUserTeamsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactPersonUserTeamsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user_teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactRelationsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"relations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactRelationsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"relations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactSchoolClassesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"school_classes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactSchoolClassesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"school_classes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactSchoolYearsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"school_years"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactSchoolYearsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"school_years"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactSupplierSupportedPhotoQualitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"supplier_supported_photo_qualities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactSupplierSupportedPhotoQualitiesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"supplier_supported_photo_qualities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactSupplierSupportedSheetsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"supplier_supported_sheets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactSupplierSupportedSheetsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"supplier_supported_sheets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactUniCUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"uni_c"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactUniCPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"uni_c"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactUserUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantContactUserPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantContactsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantContactsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantContactsMergeUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantContactsMergePath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"merge"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantContactsUpdateNumberOfStudentsFromCsvUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"update-number-of-students-from-csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantContactsUpdateNumberOfStudentsFromCsvPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"update-number-of-students-from-csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantConversationsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantConversationsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"conversations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantEconomyAccountDimensionsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dimensions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantEconomyAccountDimensionsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dimensions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantEconomyAccountsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomyAccountsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEconomyDimensionLockedDimensionsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"dimension"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"locked-dimensions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantEconomyDimensionLockedDimensionsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"dimension"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"locked-dimensions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantEconomyDimensionsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"dimensions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomyDimensionsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"dimensions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEconomyEmployeesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomyEmployeesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"employees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEconomyInvoicesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomyInvoicesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEconomyProductsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomyProductsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEconomyPropertiesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomyPropertiesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEconomySubscriptionRenewalGenerationsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantEconomySubscriptionRenewalGenerationsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"economy"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantEditAbilityGroupUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditAbilityGroupPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditActivityUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditActivityPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditActivitySurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditActivitySurveyPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditCallListUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditCallListPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditCompetitionSurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competition_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditCompetitionSurveyPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competition_surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditContactUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditContactPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditContactRelationshipUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contact_relationships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditContactRelationshipPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contact_relationships"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditHighscoreUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditHighscorePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditHighscoreClassStepUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_class_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditHighscoreClassStepPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_class_steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditInvoiceUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditInvoicePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditOrganizationUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditOrganizationPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditPlanUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditPlanPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditRoleUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditRolePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditRoleAbilityUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"role_abilities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditRoleAbilityPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"role_abilities"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditSurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditSurveyPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditSurveyStepUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]], true),

  tenantEditSurveyStepPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]),

  tenantEditSurveyStepElementUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"step_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"step_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]], true),

  tenantEditSurveyStepElementPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"step_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"step_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]]]),

  tenantEditTeamUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditTeamPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditTeamUserUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"team_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditTeamUserPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"team_users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantEditUserUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantEditUserPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantHighscorePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantHighscoreClassStepsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"class_steps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreClassStepsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"class_steps"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreCommunesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"communes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreCommunesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"communes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreCompetitionsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"competitions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreCompetitionsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"competitions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreExcludedUsersUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"excluded_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreExcludedUsersPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"excluded_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreGenerationUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantHighscoreGenerationPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantHighscoreGenerationOverallHighscoreUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"overall_highscore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreGenerationOverallHighscorePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"overall_highscore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreGenerationParticipantsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreGenerationParticipantsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"participants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreGenerationSurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generation_surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantHighscoreGenerationSurveyPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_generation_surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantHighscoreGenerationsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreGenerationsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreOrganizationsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreOrganizationsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoreRegionsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantHighscoreRegionsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"regions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantHighscoresUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantHighscoresPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantInvoiceUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantInvoicePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantInvoiceLogsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantInvoiceLogsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantLogsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantLogsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantNavigationUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"navigations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNavigationPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"navigations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNavigationsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"navigations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantNavigationsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"navigations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantNewAbilityGroupUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewAbilityGroupPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"ability_groups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewActivityUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewActivityPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewActivitySurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_surveys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewActivitySurveyPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"activity_surveys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewCallListUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewCallListPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"call_lists"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewCompetitionUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewCompetitionPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"competitions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewContactUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewContactPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contacts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewContactRelationshipUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contact_relationships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewContactRelationshipPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"contact_relationships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewHighscoreUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewHighscorePath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscores"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewHighscoreClassStepUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_class_steps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewHighscoreClassStepPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"highscore_class_steps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewInvoiceUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewInvoicePath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"invoices"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewOrganizationUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewOrganizationPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewPlanUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewPlanPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewRoleUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewRolePath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewRoleAbilityUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"role_abilities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewRoleAbilityPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"role_abilities"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewStudentCouncilMembershipUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"student_council_memberships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewStudentCouncilMembershipPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"student_council_memberships"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewSurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewSurveyPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewSurveyStepUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantNewSurveyStepPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantNewSurveyStepElementUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"step_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"step_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true),

  tenantNewSurveyStepElementPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"step_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"step_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  tenantNewTeamUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewTeamPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewTeamUserUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"team_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewTeamUserPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"team_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantNewUniLoginUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantNewUniLoginPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantOrderUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantOrderPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantOrdersUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,""],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantOrdersPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,""],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantOrdersPhotoSheetProductOrderLineGenerationsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sheet_product_order_line_generations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantOrdersPhotoSheetProductOrderLineGenerationsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sheet_product_order_line_generations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantOrdersProcessOrdersUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"process_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantOrdersProcessOrdersPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"orders"],[2,[7,"/"],[2,[6,"process_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantOrganizationUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantOrganizationPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantOrganizationLogsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantOrganizationLogsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantOrganizationSchoolClassesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"school_classes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantOrganizationSchoolClassesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"school_classes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantOrganizationTeamsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantOrganizationTeamsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantOrganizationUsersUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantOrganizationUsersPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantOrganizationsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantOrganizationsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantPhotoUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantPhotoPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photos"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantPhotoSessionUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sessions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantPhotoSessionPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sessions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantPhotoSessionPhotoSessionGroupsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sessions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"photo_session_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantPhotoSessionPhotoSessionGroupsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sessions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"photo_session_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantPhotoSessionsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantPhotoSessionsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photo_sessions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantPhotosUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantPhotosPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"photos"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantPlanUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantPlanPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantPlanSubscriptionsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantPlanSubscriptionsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"subscriptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantPlansUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantPlansPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"plans"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantProductUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantProductPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantReimbursementsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantReimbursementsAllAttachmentsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"all-attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsAllAttachmentsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"all-attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsExpensesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"expenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsExpensesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"expenses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsMyAttachmentsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"my-attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsMyAttachmentsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"my-attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsOverviewUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsOverviewPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsProcessUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"process"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsProcessPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"process"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsTimeUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"time"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsTimePath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"time"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantReimbursementsTravelUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"travel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantReimbursementsTravelPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"reimbursements"],[2,[7,"/"],[2,[6,"travel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantRoleUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantRolePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantRoleAbilitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantRoleAbilitiesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"abilities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantRoleAbilityGroupsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ability_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantRoleAbilityGroupsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ability_groups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantRolesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantRolesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantSchoolClassUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"school_classes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantSchoolClassPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"school_classes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantSchoolClassUsersUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"school_classes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantSchoolClassUsersPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"school_classes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantSubjectsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantSubjectsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subjects"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantSubscriptionUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantSubscriptionPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantSubscriptionPeriodsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantSubscriptionPeriodsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscriptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantSubscriptionRenewalGenerationUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantSubscriptionRenewalGenerationPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantSubscriptionRenewalGenerationInvoicesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantSubscriptionRenewalGenerationInvoicesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"invoices"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantSubscriptionRenewalGenerationPeriodsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantSubscriptionRenewalGenerationPeriodsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"subscription_renewal_generations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"periods"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantSuccessfulUniLoginUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"successful"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantSuccessfulUniLoginPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"uni_logins"],[2,[7,"/"],[2,[6,"successful"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantSurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantSurveyPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantSurveyStepUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantSurveyStepPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantSurveyStepElementUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"step_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"step_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]], true),

  tenantSurveyStepElementPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"step_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"steps"],[2,[7,"/"],[2,[3,"step_id"],[2,[7,"/"],[2,[6,"elements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]]]),

  tenantSurveySurveyJudgesUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"survey_judges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantSurveySurveyJudgesPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"survey_judges"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantSurveyTeamSurveyUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantSurveyTeamSurveyPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantSurveyTeamSurveysUrl: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantSurveyTeamSurveysPath: __jsr.r({"current_tenant_id":{"r":true},"survey_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[2,[7,"/"],[2,[3,"survey_id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantSurveysUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantSurveysPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantTagsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantTagsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantTeamUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantTeamPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantTeamSurveysUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantTeamSurveysPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantTeamsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantTeamsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantTenantUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantTenantPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantTenantDomainsUrl: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantTenantDomainsPath: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"domains"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantTenantIntegrationsBamboraUrl: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"bambora"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantTenantIntegrationsBamboraPath: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"bambora"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantTenantIntegrationsInventioUrl: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"inventio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantTenantIntegrationsInventioPath: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"inventio"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantTenantIntegrationsShipmondoUrl: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"shipmondo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantTenantIntegrationsShipmondoPath: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"integrations"],[2,[7,"/"],[2,[6,"shipmondo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantTenantSetupUrl: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"setup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantTenantSetupPath: __jsr.r({"current_tenant_id":{"r":true},"tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"tenant_id"],[2,[7,"/"],[2,[6,"setup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantTenantsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantTenantsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"tenants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantUserUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantUserPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantUserLogsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantUserLogsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantUserSettingsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"user_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantUserSettingsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"user_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantUserTeamSurveysUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantUserTeamSurveysPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantUserTeamsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantUserTeamsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantUsersUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  tenantUsersPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  tenantWebshopBundleUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantWebshopBundlePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantWebshopBundleTenantPhotoProductsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tenant_photo_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantWebshopBundleTenantPhotoProductsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"tenant_photo_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantWebshopBundlesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopBundlesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopEditBundleUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantWebshopEditBundlePath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantWebshopEditPhotoSheetProductUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantWebshopEditPhotoSheetProductPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantWebshopNewBundleUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantWebshopNewBundlePath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"bundles"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantWebshopNewPhotoSheetProductUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantWebshopNewPhotoSheetProductPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantWebshopPhotoColorsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_colors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopPhotoColorsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_colors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopPhotoProductsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopPhotoProductsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopPhotoQualitiesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_qualities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopPhotoQualitiesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_qualities"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopPhotoSheetProductUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantWebshopPhotoSheetProductPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantWebshopPhotoSheetProductSheetLayoutsUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sheet_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]], true),

  tenantWebshopPhotoSheetProductSheetLayoutsPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sheet_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]),

  tenantWebshopPhotoSheetProductsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopPhotoSheetProductsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_sheet_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopPhotoTypesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopPhotoTypesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"photo_types"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopSheetLayoutUrl: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"sheet_layouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]], true),

  tenantWebshopSheetLayoutPath: __jsr.r({"current_tenant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"sheet_layouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]),

  tenantWebshopSheetLayoutsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"sheet_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopSheetLayoutsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"sheet_layouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopSheetSizesUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"sheet_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopSheetSizesPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"sheet_sizes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  tenantWebshopTenantPhotoProductsUrl: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"tenant_photo_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  tenantWebshopTenantPhotoProductsPath: __jsr.r({"current_tenant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"tenants"],[2,[7,"/"],[2,[3,"current_tenant_id"],[2,[7,"/"],[2,[6,"webshop"],[2,[7,"/"],[2,[6,"tenant_photo_products"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  termsAndConditionsUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms_and_conditions"],[1,[2,[8,"."],[3,"format"]]]]], true),

  termsAndConditionsPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"terms_and_conditions"],[1,[2,[8,"."],[3,"format"]]]]]),

  turboRecedeHistoricalLocationUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recede_historical_location"],[1,[2,[8,"."],[3,"format"]]]]], true),

  turboRecedeHistoricalLocationPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"recede_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]),

  turboRefreshHistoricalLocationUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refresh_historical_location"],[1,[2,[8,"."],[3,"format"]]]]], true),

  turboRefreshHistoricalLocationPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"refresh_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]),

  turboResumeHistoricalLocationUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resume_historical_location"],[1,[2,[8,"."],[3,"format"]]]]], true),

  turboResumeHistoricalLocationPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"resume_historical_location"],[1,[2,[8,"."],[3,"format"]]]]]),

  updateRailsDiskServiceUrl: __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  updateRailsDiskServicePath: __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  userUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  userPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  userConfirmationUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  userConfirmationPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  userConfirmationDuplicate1Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  userConfirmationDuplicate1Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  userConfirmationDuplicate2Url: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]], true),

  userConfirmationDuplicate2Path: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"confirmation"],[1,[2,[8,"."],[3,"format"]]]]]]]),

  userLogsUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  userLogsPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  userOpenidConnectOmniauthAuthorizeUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"openid_connect"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  userOpenidConnectOmniauthAuthorizePath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"openid_connect"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  userOpenidConnectOmniauthCallbackUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"openid_connect"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]], true),

  userOpenidConnectOmniauthCallbackPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"auth"],[2,[7,"/"],[2,[6,"openid_connect"],[2,[7,"/"],[2,[6,"callback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]),

  userTeamSurveysUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  userTeamSurveysPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"team_surveys"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  userTeamsUrl: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]], true),

  userTeamsPath: __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"teams"],[1,[2,[8,"."],[3,"format"]]]]]]]]]),

  usersUrl: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]], true),

  usersPath: __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]])}
,
    };
    Utils.define_module("NIL", result);
    return result;
})(this);

