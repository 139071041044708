import {Routes as jsRoutes} from "shared/js-routes"
import routeDefinitions from "admin/route-definitions.json"
import {Routes} from "@kaspernj/api-maker"

const adminRoutes = new Routes({jsRoutes, routeDefinitions})

// Create helper methods to routes without defining tenant with current tenant ID
for (const routeMethodName in adminRoutes) {
  if (!routeMethodName.startsWith("tenant")) {
    continue
  }

  const newRouteMethodName = `${routeMethodName[6].toLowerCase()}${routeMethodName.substring(7, routeMethodName.length)}`

  adminRoutes[newRouteMethodName] = (...routeParams) => {
    const newRouteParams = [...routeParams]

    if (newRouteParams && newRouteParams[0] == ":current_tenant_id") {
      // This handles as routes are initialised and should be passed through
      return adminRoutes[routeMethodName](...newRouteParams)
    }

    let idParams, options

    if (Array.isArray(newRouteParams[0])) {
      idParams = newRouteParams.shift()
      options = newRouteParams.shift()

      idParams.unshift(CurrentTenant.currentId())

      newRouteParams.unshift(idParams)
      newRouteParams.unshift(options)
    } else {
      newRouteParams.unshift([CurrentTenant.currentId()])
    }

    return adminRoutes[routeMethodName](...newRouteParams)
  }
}

export default adminRoutes
