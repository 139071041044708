import {Loader} from "@googlemaps/js-api-loader"

export default class GoogleMapsLoader {
  static #loadedOrLoading = []

  static #loaders = []

  static load(libraries) {
    const librariesToLoad = libraries.filter((library) => !this.#loadedOrLoading.includes(library))

    if (librariesToLoad.length > 0) {
      librariesToLoad.forEach((library) => this.#loadedOrLoading.push(library))

      const googleMapsApiKey = digg(Configuration, "GOOGLE_MAPS_API_KEY")
      const loader = new Loader({
        apiKey: googleMapsApiKey,
        version: "weekly",
        libraries: [librariesToLoad]
      })

      this.#loaders.push(loader.load())
    }

    return Promise.all(this.#loaders)
  }
}
