// TODO this file should be removed and use recoil instead
export default class CurrentTenant {
  static current() {
    if (!window.currentTenant) {
      const currentTenantId = CurrentTenant.currentId()
      const currentTenantData = window.currentTenantData
      const currentTenantDataId = digg(currentTenantData, "a", "id")

      if (currentTenantDataId != currentTenantId) {
        throw new Error(`Data from current tenant ID didn't match data: ${currentTenantId} - ${currentTenantDataId}`)
      }

      const tenant = new Tenant({data: currentTenantData})

      window.currentTenant = tenant
      delete window.currentTenantData
    }

    return window.currentTenant
  }

  static currentId() {
    return digg(window, "currentTenantId")
  }

  static reset() {
    if (window.currentTenant) {
      window.currentTenant = undefined
      window.currentTenantId = undefined
    }
  }

  static setCurrentTenant(tenant) {
    window.currentTenant = tenant
    window.currentTenantId = tenant.id()
  }
}
