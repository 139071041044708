import getCurrentLayout from "tenants/get-current-layout"

export default function getUrlToLayout(tenantDomain, pathName, ...args) {
  const currentLayout = getCurrentLayout()
  const fullPathName = `${pathName}Path`
  let routes
  const layout = tenantDomain.layout() || currentLayout

  if (layout === "admin") {
    routes = AdminRoutes
  } else if (layout === "nemoa") {
    routes = NemoaRoutes
  } else if (layout === "merfoto") {
    routes = MerfotoRoutes
  } else {
    throw new Error(`Unknown layout: ${layout}`)
  }

  if (!routes[fullPathName]) {
    throw new Error(`No such route on the ${layout} layout: ${fullPathName}`)
  }

  if (!tenantDomain) {
    throw new Error(`No tenant domain when redirecting from the ${currentLayout} layout to ${layout}`)
  }

  // Support that we can pass urlArgs
  const lastArg = args[args.length - 1]

  let urlArgsFromArgs

  if (typeof lastArg == "object") {
    urlArgsFromArgs = lastArg
    args.pop()
  }

  const urlArgs = {
    host: tenantDomain.name(),
    ...urlArgsFromArgs
  }

  // We need to include the port when testing because Capybara runs on a random port
  if (window.RAILS_ENV === "test" || window.RAILS_ENV === "development") {
    urlArgs.port = location.port
  }

  const fullUrlName = `${pathName}Url`
  const path = routes[fullUrlName](...args, urlArgs)

  return path
}
