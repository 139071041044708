import {Controller} from "stimulus"

export default class CkeditorController extends Controller {
  connect() {
    const configPath = digg(window, "ckeditorCustomConfigPath")
    const nodeName = this.element.nodeName
    const config = this.element.dataset.config && JSON.parse(this.element.dataset.config)

    this.editor = CKEDITOR.replace(this.element, {
      customConfig: configPath,
      ...config
    })

    if (nodeName !== "TEXTAREA") {
      console.error(`Nodename wasn't TEXTAREA. It will most likely cause CKEditor not able to save. Node name was: ${nodeName}`)
    }

    // Update the value of the textarea when a change is made
    this.editor.on("change", () => this.editor.updateElement())
  }

  disconnect() {
    this.editor.destroy()
  }
}
