import "./style"

export default class Tooltip extends BaseComponent {
  static defaultProps = {
    active: true,
    alignCenter: false
  }

  static propTypes = {
    active: PropTypes.bool.isRequired,
    alignCenter: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    tip: PropTypes.node.isRequired
  }

  state = ({
    clientX: null,
    clientY: null,
    display: "none",
    tipShown: false
  })

  render() {
    const {active, alignCenter, children, className, tip, ...restProps} = this.props
    const {tipShown} = digs(this.state, "tipShown")

    return (
      <span
        className={this.className()}
        onMouseMove={this.onMouseMove}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        {...restProps}
      >
        {children}
        {active && tipShown &&
          <div className="tip-container" ref="tipContainer">
            {tip}
          </div>
        }
      </span>
    )
  }

  className() {
    const {alignCenter} = digs(this.props, "alignCenter")
    const {className} = this.props

    return classNames(
      "components-shared-tooltip",
      className,
      {
        "align-items-center d-flex": alignCenter
      }
    )
  }

  mousePosition = (e) => {
    const {active} = digs(this.props, "active")
    const {tipContainer} = this.refs

    let clientX = e.clientX + 15,
      clientY = e.clientY + 15,
      containerHeight, containerWidth,
      display = "none"

    if (tipContainer) {
      containerWidth = tipContainer.clientWidth
      containerHeight = tipContainer.clientHeight
    }

    if (containerWidth && containerHeight) {
      const maxX = window.innerWidth - 16 - containerWidth
      const maxY = window.innerHeight - 16 - containerHeight

      if (clientX >= maxX) {
        clientX = e.clientX - containerWidth - 15
      }

      if (clientY >= maxY) {
        clientY = e.clientY - containerHeight - 15
      }
    }

    if (active && clientX && clientY) {
      display = "block"
    }

    return {clientX, clientY, display}
  }

  // This is done with normal JS because it is lighter than setState in React
  setMousePosition = (args) => {
    const {clientX, clientY, display} = args
    const {tipContainer} = this.refs

    if (tipContainer) {
      tipContainer.style.display = display
      tipContainer.style.left = `${clientX}px`
      tipContainer.style.top = `${clientY}px`
    }
  }

  onMouseMove = (e) => {
    const mousePosition = this.mousePosition(e)

    this.setMousePosition(mousePosition)
  }

  onMouseOver = (e) => {
    const mousePosition = this.mousePosition(e)

    this.setState({tipShown: true}, () => this.setMousePosition(mousePosition))
  }

  onMouseOut = () => {
    this.setState({display: "none", tipShown: false})
  }
}
