import "./style"
import FlashMessage from "./flash-message"

export default class FlashMessagesIndex extends React.Component {
  count = 0
  state = {
    flashMessages: []
  }

  render() {
    const {flashMessages} = this.state

    return (
      <div className="nemoa-components-flash-messages-index">
        <EventListener event="pushFlashMessage" onCalled={this.onPushFlashMessage} target={window} />

        {flashMessages.map((flashMessage) =>
          <FlashMessage
            className="mb-3"
            key={`flash-message-${digg(flashMessage, "count")}`}
            message={flashMessage.message}
            onRequestRemove={() => this.onRequestRemove(flashMessage)}
            {...flashMessage}
          />
        )}
      </div>
    )
  }

  onPushFlashMessage = (event) => {
    const count = this.count
    const detail = digg(event, "detail")
    const component = dig(detail, "args", "component")
    const timeout = dig(detail, "args", "timeout")

    this.count += 1

    const flashMessage = {
      component,
      count,
      message: detail.message,
      timeout: timeout !== false ? 4000 : timeout,
      title: detail.title,
      type: digg(detail, "type")
    }

    this.setState((prevState) => ({
      flashMessages: prevState.flashMessages.concat([flashMessage])
    }))
  }

  onRequestRemove(flashMessage) {
    this.setState((prevState) => ({flashMessages: prevState.flashMessages.filter((filteredFlashMessage) => filteredFlashMessage.count != flashMessage.count)}))
  }
}
