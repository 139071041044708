import {Controller} from "stimulus"
import GoogleMapsLoader from "shared/google-maps-loader"

export default class GoogleMapsController extends Controller {
  async connect() {
    const element = this.element
    const mapOptions = {
      center: {
        lat: 0,
        lng: 0
      },
      zoom: 0
    }

    try {
      await GoogleMapsLoader.load(["geometry", "places"])
    } catch (error) {
      FlashMessage.errorResponse(error)

      return
    }

    const map = new google.maps.Map(element, mapOptions)

    if (this.data.get("directions")) {
      this.drawDirectionsOnMap(map)
    }
  }

  drawDirectionsOnMap(map) {
    const bounds = new google.maps.LatLngBounds()
    const directions = JSON.parse(this.data.get("directions"))
    const legs = directions.routes[0].legs

    const markers = [{position: legs[0].start_location, title: legs[0].start_address}]
    const path = []

    for (const leg of legs) {
      const steps = leg.steps

      markers.push({position: leg.end_location, title: leg.end_address})

      for (const step of steps) {
        const pathElements = google.maps.geometry.encoding.decodePath(step.polyline.points)

        for (const pathElement of pathElements) {
          path.push(pathElement)
          bounds.extend(pathElement)
        }
      }
    }

    // draw markers
    let i = 1

    for (const marker of markers) {
      // eslint-disable-next-line no-new
      new google.maps.Marker({
        label: `${i}`,
        map,
        position: marker.position,
        title: marker.title
      })
      i++
    }

    // eslint-disable-next-line no-new
    new google.maps.Polyline({
      map,
      path,
      strokeColor: "#DC143C",
      strokeWeight: 3
    })

    map.fitBounds(bounds)
  }
}
