import {useNavigate, useParams} from "react-router-dom"
import {useLayoutRedirector} from "./hooks/use-layout-redirector"
import {useMemo} from "react"

function LazyRoute({component, requireComponent}) {
  const Component = useMemo(() => requireComponent(component), [component, requireComponent])
  const layoutRedirector = useLayoutRedirector()
  const navigate = useNavigate()
  const params = useParams()

  return (
    <Component
      match={{params}}
      router={{layoutRedirector, navigate}}
    />
  )
}

export function readRoutes({requireComponent, routeDefinitions}) {
  const routes = []

  for (const routeDefinition of routeDefinitions.routes.filter((routeDefinition) => routeDefinition.component)) {
    routes.push({
      element: <LazyRoute component={routeDefinition.component} requireComponent={requireComponent} />,
      path: routeDefinition.path
    })
  }

  return routes
}
