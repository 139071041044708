import * as Sentry from "@sentry/react"
import {BrowserTracing} from "@sentry/tracing"
import {CommandsPool} from "@kaspernj/api-maker"
import CurrentTenant from "../tenants/current-tenant"
import {RecoilRoot} from "recoil"
import updateCurrentUser from "./users/update-current-user"
import {UserContext} from "shared/user-context"

if (window["sentryDsn"]) {
  Sentry.init({
    dsn: window["sentryDsn"],
    integrations: [new BrowserTracing()]
    // tracesSampleRate: 0.1
  })
}

export class App extends React.Component {
  setUser = async (user) => {
    await updateCurrentUser(user) // TODO Deprecated: update current user for legacy support
    this.setState({userContext: [Devise.currentUser(), this.setUser]})
  }

  // TODO this can probably be removed but we'll have to joblers etc with another tenant than the tenant domain
  componentDidMount() {
    this.updateTenantInCommandsPool()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tenantContext !== this.state.tenantContext) {
      this.updateTenantInCommandsPool()
    }
  }

  updateTenantInCommandsPool() {
    const currentTenantId = CurrentTenant.currentId()

    if (!CommandsPool.current().globalRequestData) {
      CommandsPool.current().globalRequestData = {}
    }

    if (currentTenantId) {
      CommandsPool.current().globalRequestData.current_tenant_id = currentTenantId

      if (window.currentTenant?.id() !== currentTenantId) {
        window.currentTenant = null
      }

      window.currentTenantId = currentTenantId
    } else {
      delete CommandsPool.current().globalRequestData.current_tenant_id
    }
  }

  state = {
    userContext: [Devise.currentUser(), this.setUser]
  }

  render() {
    const {children} = this.props
    const {userContext} = this.state

    return (
      <UserContext.Provider value={userContext}>
        <RecoilRoot>
          <EventEmitterListener event="onDeviseSignIn" events={Devise.events()} onCalled={this.onUserUpdated} />
          <EventEmitterListener event="onDeviseSignOut" events={Devise.events()} onCalled={this.onUserUpdated} />
          {children}
        </RecoilRoot>
      </UserContext.Provider>
    )
  }

  onUserUpdated = () => {
    this.setState({userContext: [Devise.currentUser(), this.setUser]})
  }
}
